import { NavigateFunction, useLocation } from "react-router-dom"
import { headers } from "../../App/Axios"
import HTTPService from "../../App/HTTPService"
import { header } from "../Shared/PhotoDropdown/PhotoDropdownServiceRequests"
import { useSelector } from "react-redux"
import { RootState } from "../../App/store"
import { AxiosResponse } from "axios"

export const chatListGetRequest = (pageNumber:number=1) => {
    return HTTPService.get(`conversation?page=${pageNumber}`,headers)
}

export const chatGetByPartnerIdRequest = (partnerId:number
) => {
    return HTTPService.get(`conversation/partnerId/${partnerId}`,headers)
}

export const singleChatListGetRequest = (conversationId : number) => {
    return HTTPService.get(`conversation/${conversationId}`,headers)
}

export const ConversationMessageGetRequest = (conversationId: number, pageNumber:number) => {
    return HTTPService.get(`/conversation/${conversationId}/messages?page=${pageNumber}`, headers)
}

export const CreateConversationPostRequest = (partnerId: number,userId: number) => {
    return HTTPService.post(`conversation/`, { member: partnerId } , headers)
}

export const ConversationLeaveRequest = (conversationId: number) => {
    return HTTPService.delete(`/conversation/${conversationId}/leave`)
}
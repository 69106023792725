import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CallerComponent, JoinStatus, LabelType, RoleType, RoomType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import MessageBody from "../../Shared/Components/MessageBody"
import { InviteUserIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import ManagersCard from "../Components/ManagersCard"
import RoomInvitationLinkButton from "../Components/RoomInvite/RoomInvitationLinkButton"
import RoomMembers from "../Components/RoomMembers"
import RoomUserCard from "../Components/RoomUserCard"
import TutorialBubble from "../../Shared/Components/TutorialBubble"

export type RoomInformationType = {
    isMemberInRoom: string
    hasPrivelegedPermission: boolean
}

function RoomInformation(props: RoomInformationType) {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [isChecked, setIsChecked] = useState(false)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const InviteLinkPermission = role === RoleType.USER ? props.hasPrivelegedPermission : roomDataState.organization?.id === organizationDataState.id
    const RoomInvitePermissions = roomDataState.type === RoomType.Private ? roomDataState?.room_permissions[0].invite_others : true

    const openInviteUserModal = () => {
        dispatch(setModalData({ open: true, roomInviteModal: true }))
    }

    return (
        <div className="room-layout__left-panel">
            <div className="room-layout__left-panel-content">
                <div className="room-description">
                    <h2 className="room-description__title">{translation.description}</h2>
                    <MessageBody
                        text={roomDataState?.description!}
                        showPreview={false}
                        showCollapsed={false}
                        showRemovePreviewIcon={false}
                        hashtags={roomDataState!.hashtags!}
                        isCollapsed={isChecked}
                        caller={CallerComponent.roomDescription}
                        toggleCollapse={() => {
                            setIsChecked(!isChecked)
                        }}
                    />
                </div>
                {!isChecked && (
                    <>
                        <RoomUserCard type={LabelType.ROOM_CREATOR} />
                        <ManagersCard />
                        {roomDataState.is_active && roomDataState.can_user_join_or_invite && (
                            <>
                            <div className="room-layout__left-panel-button">
                            {(props.isMemberInRoom === JoinStatus.MEMBER && roomDataState.type && RoomInvitePermissions) || props.hasPrivelegedPermission ? (
                                <Button
                                    className="button button--medium button--border-radius-05 button--cursor-pointer button--hover-brighter button--color-gold button--text-color-white "
                                    onClick={openInviteUserModal}
                                >
                                    <div className="room-layout__left-panel-button-content">
                                        <div className="room-layout__left-panel-button-icon">
                                            <InviteUserIcon color="white" />
                                        </div>
                                        {translation.invite}
                                    </div>
                                </Button>
                            ) : null}
                        </div>
                        {InviteLinkPermission && roomDataState.type === RoomType.Private && roomDataState.can_user_join_or_invite ? (
                            <RoomInvitationLinkButton />
                        ) : roomDataState.type === RoomType.Public ? (
                            <RoomInvitationLinkButton />
                        ) : null}
                        </>
                        )}
                        <TutorialBubble text={translation.tutorial_org_room_invite} modifier="left speechbubble__room-invite-button" visible={roomDataState.organization?.id === organizationDataState.id && organizationDataState.tutorial_progress === 4} hasNext={true} routeOnNext={`/organization-dashboard/${organizationDataState.id}`} />
                        
                    </>
                )}
            </div>
        </div>
    )
}

export default RoomInformation

import React, { useEffect, useState } from "react"
import "../../../Styles/main.scss"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../App/store"
import { getSignupErrorStatus, setFormData } from "../SignUp/SignupSlice"
import StorageService from "../../../App/StorageService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { StorageKey } from "../../../App/enums"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import SessionService from "../../../App/SessionService"
import { LoadingAnimation, LogoIcon } from "../../Shared/Components/SvgIcons"
import { edusiiaMatchingPostRequestWithOrganization } from "../EdusiiaMatching/EdusiiaMatchingServiceRequests"
import TextInput from "../../Shared/Primitive/TextInput"
import ToggleSwitch from "../../Shared/Components/ToggleSwitch"
import Dropdown from "../../Shared/Primitive/Dropdown"
import SearchableDropdown from "../../Shared/Components/SearchableDropdown"
import { orgCategoriesGetRequest } from "../../Authentication/SignUp/SignupServiceRequests";

function CreateOrganizationForm() {
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.signupSlice.signupData)
    const statusState = useSelector((state: RootState) => state.EdusiiaMatchingSlice.edusiiaMatchingStatus)
    const signupErrorStatusState = useSelector((state: RootState) => state.signupSlice.singupErrorStatus)
    const addOrganizationError = useSelector((state: RootState) => state.EdusiiaMatchingSlice.addOrganizationError.message.organization)
    const PrivacyPolicyError = useSelector((state: RootState) => state.EdusiiaMatchingSlice.privacyPolicyError)
    const localSignupForm = StorageService.parse(StorageService.getItem(StorageKey.signupForm) as string)
    const [organizationName, setOrganizationName] = React.useState("")
    const [isRepresentativeChecked, setIsRepresentativeChecked] = React.useState(false)
    const [isProfit, setIsProfit] = useState(false)
    const [organizationEmail, setOrganizationEmail] = React.useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [organizationState, setOrganizationState] = useState(0)
    const [typeOfOrganization, setTypeOfOrganization] = useState(0)
    const [typeOfOrg,setTypeofOrgArray] = useState([{ id: 0, name: ""}])
    const language = StorageService.hasItem("language") ? StorageService.getItem("language") : 'deutsch'
    const locale = language === 'english' ? 'en' : 'de'
    const name_identifier = 'name_'+locale
    const [permissionForm, setEdusiiaMatchingForm] = useState({
        privacy_policy: false,
        newsletter: false,
    })
    let validationSuccess: boolean = false
    const [typeOfOrgFieldError, setTypeOfOrgFieldError] = useState("")
    const [orgStateFieldError, setOrgStateFieldError] = useState("")
    const [isRepresentativeFieldError, setIsRepresentativeFieldError] = useState("")

    const inviteId = SessionService.getItem(StorageKey.roomInvitationId) as string

    const onToggleChange = () => {
        setIsProfit(!isProfit)
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEdusiiaMatchingForm({ ...permissionForm, [event.target.name]: event.target.checked })
    }

    useEffect(() => {
        let isMounted = true
        orgCategoriesGetRequest()
            .then(response => {
                if (isMounted && response.status === 200) {
                    let fixedOptions = [
                        {
                            id: 14,
                            name_de: "Sonstige",
                            name_en: "Other",
                        },
                        {
                            id: 15,
                            name_de: "Möchte ich nicht angeben",
                            name_en: "Prefer not to say",
                        },
                    ]

                    response.data = [...response.data,...fixedOptions]

                    response.data.forEach( function(data: { [x: string]: any }) {
                        
                        data['name'] = data[name_identifier];
                        delete data['name_de'];
                        delete data['name_en'];
                      });

                    setTypeofOrgArray(response.data)
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log(error.response)
            })
        return () => {
            isMounted = false
        }
    }, [])

    useEffect(() => {
        dispatch(
            setFormData({
                first_name: localSignupForm.name,
                last_name: localSignupForm.lastName,
                email: localSignupForm.email,
                password: localSignupForm.password,
                confirm_password: localSignupForm.confirmPassword,
                reference_id: localSignupForm.id,
                privacy_settings: localSignupForm.privacy_settings,
                room_invitation_id: inviteId,
                edusiia_matching: localSignupForm.edusiia_matching,
                location_state_id: localSignupForm.location_state_id,
                involved_organisation_category_id: localSignupForm.involved_organisation_category_id,
            })
        )
    }, [])

    const onFormValidation = () => {
        if (organizationName.length > 0 && organizationState === 0) {
            setOrgStateFieldError(translation.thisFieldIsRequired)
        }else{
            setOrgStateFieldError("")
        }
        if(organizationName.length > 0 && typeOfOrganization === 0){
            setTypeOfOrgFieldError(translation.thisFieldIsRequired)
        }
        else{
            setTypeOfOrgFieldError("")
        }
        if(organizationName.length > 0 && isRepresentativeChecked === false){
            setIsRepresentativeFieldError(translation.thisFieldIsRequired)
        }else{
            setIsRepresentativeFieldError("")
        }
        if(orgStateFieldError.length === 0 && typeOfOrgFieldError.length === 0 && isRepresentativeFieldError.length === 0){
            validationSuccess = true
        }else{
            validationSuccess = false
        }
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        onFormValidation()

        if(validationSuccess === true){
            const organization = {
                name: organizationName,
                email: organizationEmail,
                is_active: true,
                representative: isRepresentativeChecked,
                organization_type: isProfit ? 1 : 2,
                description: "",
                location_state_id: organizationState,
                organization_category_id: typeOfOrganization
            }
            dispatch<any>(edusiiaMatchingPostRequestWithOrganization(data, permissionForm, organization))
        }
    }

    useEffect(() => {
        if (statusState.status) {
            navigate("/")
            window.scrollTo(0, 0)

            dispatch(setModalData({ open: true, confirmEmailMessage: true }))
        }
    }, [dispatch, navigate, statusState])

    useEffect(() => {
        if (signupErrorStatusState.status === true) {
            dispatch(getSignupErrorStatus({ status: false }))
            navigate("/signup")
            window.scrollTo(0, 0)
        }
    }, [dispatch, navigate, signupErrorStatusState])
    return (
        <form className="edusiiamatching-form" onSubmit={onFormSubmit}>
            <div className="edusiiamatching-form__logo">
                <LogoIcon />
            </div>

            <div className="accordion">
                <div className="accordion__selector">
                    <h2 className="accordion__title">{translation.addOrganizationProfile}</h2> {translation.optional}
                </div>
                <p className="p3 signup-form__small-text-no-left-margin">{translation.addOrganizationIntroduction}</p>

                <div className="accordion__main-container">
                    <div className="accordion__input-container">
                        <div className="accordion__email-input ">
                            <p className="p3 accordion__text">{translation.nameOfOrganization}</p>
                            <TextInput
                                textinputsize={"small"}
                                type="text"
                                value={organizationName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganizationName(event.target.value)}
                                autoComplete="off"
                            />

                            <p className="accordion__error-message">{addOrganizationError[0]?.name}</p>
                        </div>
                        <div className="accordion__email-input accordion__email-input--margin-left">
                            <p className="p3 accordion__small-text">{translation.email}</p>{" "}
                            <TextInput
                                type="email"
                                textinputsize="small"
                                name="email"
                                value={organizationEmail}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganizationEmail(event.target.value)}
                            />
                            <p className="accordion__error-message">{addOrganizationError[0]?.email}</p>
                        </div>
                    </div>
                    <div className="accordion__switch-container">
                        <ToggleSwitch onChange={onToggleChange} name="isProfitable" checked={isProfit} />
                        <div className={`add-organization-modal__switch-text add-organization-modal__switch-text${!isProfit && "--color-red"}`}>
                            {isProfit ? translation.forProfitOrganization : translation.nonProfitOrganization}
                        </div>
                    </div>
                    <p className="p3 signup-form__small-text"> {translation.inWhichStateIsTheOrgLocated}* </p>
                    <SearchableDropdown validationError={orgStateFieldError} itemId={organizationState} setItemId={setOrganizationState} />
                    <p className="p3 signup-form__small-text"> {translation.whatTypeOfOrgDescribesYou}* </p>{" "}
                    {isLoading ? (
                        <div className="signup-form__loading-animation-container">
                            <LoadingAnimation type="dropdown" />
                        </div>
                    ) : (
                        <div className="dropdown">
                            <Dropdown
                                itemId={typeOfOrganization}
                                setItemId={setTypeOfOrganization}
                                items={typeOfOrg}
                                placeholder={translation.chooseMostApplicable}
                                tabIndex={typeOfOrg[0].id}
                            />
                            <p className="accordion__error-message">{typeOfOrgFieldError}</p>
                        </div>
                    )}
                    <div>
                        <div className="accordion__checkbox">
                            <Checkbox
                                color="red"
                                checked={isRepresentativeChecked}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsRepresentativeChecked(event.target.checked)}
                            />
                            <p className="p3 accordion__checkbox-text">{translation.isOrganizationRepresentative}</p>
                        </div>
                        <p className="accordion__error-message accordion__error-message--representative">{addOrganizationError[0]?.representative}</p>
                    </div>
                </div>
            </div>

            {/* <AddOrganization
                organizationEmail={organizationEmail}
                setOrganizationEmail={setOrganizationEmail}
                toggleState={isProfit}
                onToggleChange={onToggleChange}
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
                isRepresentative={isRepresentativeChecked}
                setIsRepresentative={setIsRepresentativeChecked}
                nameError={addOrganizationError[0]?.name}
                representativeError={addOrganizationError[0]?.representative}
                emailError={addOrganizationError[0]?.email}
            /> */}

            <div className="edusiiamatching-form__checkbox-privacy">
                <Checkbox color="red" name="privacy_policy" checked={permissionForm.privacy_policy} onChange={onInputChange} />
                <p className="edusiiamatching-form__checkbox-privacy-text">
                    {translation.agreePrivacyPolicy}{" "}
                    <a className="  edusiiamatching-form__link" href="https://edusiia.com/datenschutz/" target="_blank" rel="noreferrer">
                        {translation.privacyPolicy}
                    </a>{" "}
                    {translation.and}{" "}
                    <a className="  edusiiamatching-form__link" href="https://edusiia.com/nutzungsbedingungen/" target="_blank" rel="noreferrer">
                        {translation.termsOfUse}
                    </a>{" "}
                    {translation.agreePrivacyPolicy1}
                    .*
                </p>
            </div>
            <p className=" p3 edusiiamatching-form__error-message">{PrivacyPolicyError.message.privacy_policy} </p>
            <div className="edusiiamatching-form__checkbox-newsletter">
                <Checkbox color="red" name="newsletter" checked={permissionForm.newsletter} onChange={onInputChange} />
                <p className=" edusiiamatching-form__checkbox-newsletter-text ">
                    {translation.receiveNewsLetter} <br></br> {translation.revokeNewsLetter}
                </p>
            </div>
            <div className="edusiiamatching-form__button-component">
                <div className=" edusiiamatching-form__button-size">
                    <Button
                        type="submit"
                        className="button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                    >
                        <div>{translation.join}</div>
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default CreateOrganizationForm
